<template>
  <ValidationObserver ref="employeeForm">
    <EKDialog
      class="ekEmployee"
      ref="employeeModal"
      title="إضافة موظف جديد"
      placeholder="ابحث عن موظف"
      btnText="موظف جديد"
      @ok="submitAddEmployee"
      @open="openDialog"
      @search="search"
    >
      <template #body>
        <EKInputText
          v-model="employeeDto.name"
          :rules="[
            {
              type: 'required',
              message: 'اختر اسم الموظف',
            },
          ]"
          label="اسم الموظف"
          placeholder="ادخل اسم الموظف"
          name="name"
        />
        <EKInputSelect
          label="منصب الموظف"
          placeholder="اختر منصب الموظف"
          :options="positionsList"
          name="positionId"
          :rules="[
            {
              type: 'required',
              message: 'يرجى اختيار منصب الموظف',
            },
          ]"
          v-model="employeeDto.positionId"
        />
        <EKInputImage
          label="صورة الموظف"
          required
          title="أرفق الملف هنا أو اضغط لتحميل"
          :value="
            employeeDto.imageDto
              ? employeeDto.imageDto.path
                ? employeeDto.imageDto.path.replace(
                    /^/,
                    $store.getters['app/domainHost'] + '/'
                  )
                : ''
              : ''
          "
          @input="addNewImage($event)"
        ></EKInputImage>
      </template>
    </EKDialog>
  </ValidationObserver>
</template>
<style></style>
<script>
import EKDialog from "@Ekcore/components/EK-dialog";
import EKInputText from "@Ekcore/components/EK-forms/EK-input-text";
import EKInputSelect from "@Ekcore/components/EK-forms/EK-input-select";
import EKInputImage from "@Ekcore/components/EK-forms/EK-input-image";
import { mapState, mapActions } from "vuex";
import { ValidationObserver } from "vee-validate";
import { nullGuid } from "@/EK-core/util/global";
export default {
  components: {
    EKDialog,
    EKInputText,
    EKInputSelect,
    EKInputImage,
    ValidationObserver,
  },
  computed: {
    ...mapState({
      employeeDto: (state) => state.employee.employeeDto,
      positionsList: (state) => state.employee.positionsList,
    }),
  },
  methods: {
    ...mapActions(["addEmployee"]),
    openDialog() {
      this.$store.commit("RESET_EMPLOYEE_FORM");
    },
    submitAddEmployee() {
      this.$refs.employeeForm.validate().then((success) => {
        if (success && this.employeeDto.imageDto.file) {
          this.addEmployee(this.employeeDto).then(() => {
            this.$store.commit("RESET_EMPLOYEE_FORM");
            this.$refs.employeeModal.close();
            this.$refs.employeeForm.reset();
          });
        }
      });
    },
    addNewImage(img) {
      this.employeeDto.imageDto = {
        id: nullGuid,
        file: img,
        path: "",
      };
    },
    search(query) {
      this.$store.commit("Set_Search_Dto", {
        keys: ["name"],
        query,
      });
    },
  },
};
</script>
