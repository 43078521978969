var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"employeeForm"},[_c('EKDialog',{ref:"employeeModal",staticClass:"ekEmployee",attrs:{"title":"إضافة موظف جديد","placeholder":"ابحث عن موظف","btnText":"موظف جديد"},on:{"ok":_vm.submitAddEmployee,"open":_vm.openDialog,"search":_vm.search},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('EKInputText',{attrs:{"rules":[
          {
            type: 'required',
            message: 'اختر اسم الموظف',
          } ],"label":"اسم الموظف","placeholder":"ادخل اسم الموظف","name":"name"},model:{value:(_vm.employeeDto.name),callback:function ($$v) {_vm.$set(_vm.employeeDto, "name", $$v)},expression:"employeeDto.name"}}),_c('EKInputSelect',{attrs:{"label":"منصب الموظف","placeholder":"اختر منصب الموظف","options":_vm.positionsList,"name":"positionId","rules":[
          {
            type: 'required',
            message: 'يرجى اختيار منصب الموظف',
          } ]},model:{value:(_vm.employeeDto.positionId),callback:function ($$v) {_vm.$set(_vm.employeeDto, "positionId", $$v)},expression:"employeeDto.positionId"}}),_c('EKInputImage',{attrs:{"label":"صورة الموظف","required":"","title":"أرفق الملف هنا أو اضغط لتحميل","value":_vm.employeeDto.imageDto
            ? _vm.employeeDto.imageDto.path
              ? _vm.employeeDto.imageDto.path.replace(
                  /^/,
                  _vm.$store.getters['app/domainHost'] + '/'
                )
              : ''
            : ''},on:{"input":function($event){return _vm.addNewImage($event)}}})]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }